import axios from "axios"

export const createCollection = data => {
  data.is_nsfw = data.is_nsfw.length > 0 ? 1 : 0
  const dataToSend = new FormData()
  dataToSend.append("name", data.name)
  dataToSend.append("family", data.family)
  dataToSend.append("symbol", data.symbol)
  dataToSend.append("description", data.description)
  dataToSend.append("supply_number", data.supply_number)
  dataToSend.append("initial_price", data.initial_price)
  dataToSend.append("is_nsfw", data.is_nsfw)
  dataToSend.append("website", data.website)
  dataToSend.append("discord", data.discord)
  dataToSend.append("telegram", data.telegram)
  dataToSend.append("instagram", data.instagram)
  dataToSend.append("twitter", data.twitter)
  dataToSend.append("minted_at", data.minted_at)
  if (data.tags.length > 0) {
    data.tags.forEach(tag => dataToSend.append(`tags[${tag}]`, tag))
  }

  if (typeof data.icon_path !== "undefined" && data.icon_path.size > 0) {
    dataToSend.append("icon_path", data.icon_path)
  }

  if (typeof data.cover_path !== "undefined" && data.cover_path.size > 0) {
    dataToSend.append("cover_path", data.cover_path)
  }

  if (
    typeof data.vertical_teaser_path !== "undefined" &&
    data.vertical_teaser_path.size > 0
  ) {
    dataToSend.append("vertical_teaser_path", data.vertical_teaser_path)
  }

  if (
    typeof data.square_teaser_path !== "undefined" &&
    data.square_teaser_path.size > 0
  ) {
    dataToSend.append("square_teaser_path", data.square_teaser_path)
  }

  return axios.post(`/api/collection`, dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const getNftsInCollection = async address => {
  return axios({
    method: "get",
    url: `/api/collection/${address}`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * Like a collection
 */
export const likeCollection = address => {
  return axios({
    method: "post",
    url: `/api/collection/${address}/like`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * Unlike a collection
 */
export const unlikeCollection = address => {
  return axios({
    method: "delete",
    url: `/api/collection/${address}/like`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * getUserCollections retrieves all user's collections
 */
export const getUserCollections = address => {
  return axios({
    method: "GET",
    url: `/api/profile/me/collection`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * getCollection retrieves a collection
 */
export const getCollection = address => {
  return axios({
    method: "GET",
    url: `/api/collection/${address}`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * addNFtInCollection adds an NFT in a collection
 */
export const addNFtInCollection = (address, data) => {
  return axios.post(`/api/collection/${address}/token`, data).then(response => {
    return response.data.data
  })
}

/**
 * getCollectionVolume retrieves a collection's volume
 */
export const getCollectionVolume = address => {
  return axios({
    method: "GET",
    url: `/api/stats/volume/collection/${address}`,
  }).then(response => {
    return response.data.data
  })
}
