import React, { useState } from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { graphql, navigate } from "gatsby"
import {
  getProfileByAddress,
  getProfileNFTs,
  followUser,
  unFollowUser,
} from "@actions/user"
import { addNFtInCollection } from "@actions/collection"
import { LAMPORT_MULTIPLIER } from "../../utils/oyster/common/src/index.tsx"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  listNft,
  unListNft,
  getNftListByAddress,
  getNftByAddress,
} from "@actions/nft"
import { useNfts } from "@contexts/nft"
import { useUser } from "@contexts/user"
import { LayoutVariant } from "@bw/layouts"
import { useQuery } from "react-query"
import { NftTeaser, CollectionTeaser, Modal } from "@bw/partials"
import { toast } from "react-toastify"
import {
  Avatar,
  Button,
  Title,
  Image,
  Container,
  Loader,
  Grid,
  Spacer,
} from "@bw/bits"
import { FormField, Input } from "@bw/forms"
import { Box } from "grommet"
import styled from "styled-components"
import { breakpoints } from "../../theme"

const ProfilePage = ({
  data: collectionsData,
  params,
  location,
  pageContext,
}) => {
  const collections = collectionsData.allCollection.nodes.filter(
    collection => collection.owner.address === params.address
  )

  const { originalPath } = pageContext
  const [user, dispatch] = useUser()
  const [nfts] = useNfts()
  const [ownedNfts, setOwnedNfts] = useState([])
  const [tab, setTab] = useState("walletNFTs")
  const { localizedPath, locale, defaultLang } = useLocalization()
  const { t } = useTranslation()

  // does the current profile page belongs to the current user ?
  const isMe = user.loggedIn && user.publicKey === params.address

  const { data, isError, status } = useQuery(
    `profile_${params.address}`,
    () => {
      return getProfileByAddress(params.address)
    }
  )

  React.useEffect(() => {
    if (isError) {
      /**
       * there is an error with the profile request.
       * It might be an invalid address format or an unexisting address
       */
      navigate(
        localizedPath({
          defaultLang,
          locale,
          path: `/`,
        })
      )
      toast.error(t("user.toastUserDoesNotExists"))
    }
  }, [isError, t, localizedPath, locale, defaultLang])

  const myNftsQuery = useQuery(["owned_nfts", params.address], () => {
    return getProfileNFTs(params.address)
  })

  const myLikesQuery = useQuery(
    ["likes", nfts.listed, user.data.likes_nfts],
    () => {
      return getNftListByAddress(user.data.likes_nfts).then(likedNfts => {
        if (nfts.loading || nfts.length === 0) {
          return likedNfts
        }
        return likedNfts.map(likedNft => {
          const foundNft = nfts.listed.find(nft => nft.nft === likedNft.address)
          return {
            ...likedNft,
            price: foundNft ? foundNft.price : <>&nbsp;</>,
          }
        })
      })
    }
  )

  React.useEffect(() => {
    if (myNftsQuery.isSuccess) {
      setOwnedNfts(myNftsQuery.data)
    }
  }, [myNftsQuery.isSuccess, myNftsQuery.data])

  const myListedNftsQuery = useQuery(
    ["listed_nfts", params.address, nfts.listed],
    () => {
      if (nfts.loading || nfts.length === 0) {
        return []
      }
      const addresses = nfts.listed.map(nft => nft.nft)

      return getNftListByAddress(addresses).then(remoteNfts =>
        nfts.listed
          // get the users nfts only
          .filter(nft => nft.seller === params.address)
          .map(nft => ({
            ...nft,
            mintData: remoteNfts.find(data => data.address === nft.nft),
          }))
      )
    }
  )

  if (typeof params.address === "undefined") {
    return null
  }

  // Availability to be follow
  const isFollowable =
    user.loggedIn &&
    Array.isArray(user.data.followings) &&
    user.data.followings.findIndex(
      following => following.address === params.address
    )

  // does the requested address has a profile on our platform ?
  const hasProfile = status === "success" && data.address

  const shortAddress =
    params.address.slice(0, 4) + "..." + params.address.slice(-4)

  return (
    <LayoutVariant
      pageContext={{
        ...pageContext,
        originalPath: originalPath.replace("[address]", params.address),
      }}
      headerContent={
        <Container>
          <SpreadGrid>
            <Content>{hasProfile && data.bio}</Content>
            <Box direction="column" align="center" gap="25px">
              <Avatar
                image={hasProfile ? data.avatar_url : null}
                alt="Profile picture"
                size="large"
              />
              <div>
                {hasProfile && <ProfileName>@{data.name}</ProfileName>}
                <Address>{shortAddress}</Address>
              </div>
            </Box>
          </SpreadGrid>
          <SpreadGrid style={{ marginTop: "15px" }}>
            <Following>
              {hasProfile && (
                <>
                  <div>
                    {data.followers_count} {t("user.followers")}
                  </div>
                  <div>
                    {data.followings_count} {t("user.follows")}
                  </div>
                </>
              )}
            </Following>
            <Box direction="row" justify="center">
              <Tabs>
                <Tab
                  onClick={() => setTab("walletNFTs")}
                  $isActive={tab === "walletNFTs"}
                >
                  {t("user.tabs.walletNFTs")}
                </Tab>
                {isMe && (
                  <>
                    <Tab
                      onClick={() => setTab("listedNFTs")}
                      $isActive={tab === "listedNFTs"}
                    >
                      {t("user.tabs.listedNFTs")}
                    </Tab>
                    <Tab
                      onClick={() => setTab("likes")}
                      $isActive={tab === "likes"}
                    >
                      {t("user.tabs.likes")}
                    </Tab>
                  </>
                )}
                {hasProfile && data.is_artist && collections.length > 0 && (
                  <Tab
                    onClick={() => setTab("collections")}
                    $isActive={tab === "collections"}
                  >
                    {t("user.tabs.collections")}
                  </Tab>
                )}
              </Tabs>
            </Box>
            <Box>
              <div>
                {user.loggedIn && isMe && (
                  <Button
                    data-cy="edit"
                    label={t("user.edit")}
                    to={localizedPath({
                      defaultLang,
                      locale,
                      path: "/user/edit/",
                    })}
                  />
                )}
                {!!(user.loggedIn && !isMe && hasProfile && isFollowable) && (
                  <Button
                    data-cy="follow"
                    icon="PlusIcon"
                    label={t("user.buttonFollow")}
                    direction="row-reverse"
                    onClick={() => {
                      followUser(params.address)
                        .then(() => {
                          toast(t("user.toastFollowSuccess"))
                          dispatch({
                            type: "FOLLOW_USER",
                            profile: data,
                          })
                        })
                        .catch(function () {
                          toast.error(t("user.toastFollowFail"))
                        })
                    }}
                  />
                )}
                {!!(user.loggedIn && !isMe && hasProfile && !isFollowable) && (
                  <Button
                    data-cy="unfollow"
                    label={t("user.buttonUnfollow")}
                    direction="row-reverse"
                    onClick={() => {
                      unFollowUser(params.address)
                        .then(() => {
                          toast(t("user.toastUnfollowSuccess"))
                          dispatch({
                            type: "UNFOLLOW_USER",
                            address: params.address,
                          })
                        })
                        .catch(function () {
                          toast.error(t("user.toastUnfollowFail"))
                        })
                    }}
                  />
                )}
              </div>
            </Box>
          </SpreadGrid>
        </Container>
      }
    >
      <Container>
        <SectionTabs>
          {tab === "walletNFTs" && (
            <div>
              {isMe && (
                <Button onClick={myNftsQuery.refetch} label={t("refresh")} />
              )}
              <Box margin={{ top: "40px" }}>
                {myNftsQuery.isLoading ? (
                  <Loader
                    messages={[
                      isMe ? t("loading.yourNft") : t("loading.userNfts"),
                      t("loading.itTakesLongerThanExpected"),
                    ]}
                    treshold={20}
                  />
                ) : myNftsQuery.status === "error" ? (
                  <div>Error</div>
                ) : (
                  <NftGrid>
                    {ownedNfts.length === 0 ? (
                      <>
                        {isMe
                          ? t("user.youHaveNoNftInWallet")
                          : t("user.hasNoNftInWallet")}
                      </>
                    ) : (
                      ownedNfts.map(nft => (
                        <NftTeaser
                          key={nft.mint}
                          to={localizedPath({
                            defaultLang,
                            locale,
                            path: `/nft/${nft.mint}/`,
                          })}
                          state={{ from: location.pathname }}
                          name={nft.mintData.name}
                          artist={nft.mintData?.collection?.name}
                          button={
                            isMe ? (
                              <ListModal
                                {...{ location, nft, ownedNfts, setOwnedNfts }}
                              />
                            ) : null
                          }
                          image={
                            <Image type="NFT" image={nft.mintData.image} />
                          }
                        />
                      ))
                    )}
                  </NftGrid>
                )}
              </Box>
            </div>
          )}
          {tab === "listedNFTs" && (
            <div>
              <Box margin={{ top: "40px" }}>
                {myListedNftsQuery.isLoading ? (
                  <Loader
                    messages={[
                      t("loading.yourNft"),
                      t("loading.itTakesLongerThanExpected"),
                    ]}
                    treshold={20}
                  />
                ) : myListedNftsQuery.status === "error" ? (
                  <div>{t("nft.form.errorOccurred")}</div>
                ) : (
                  <NftGrid>
                    {myListedNftsQuery.data.length === 0 ? (
                      <>
                        {isMe
                          ? t("user.youHaveNoListedNft")
                          : t("user.hasNoListedNft")}
                      </>
                    ) : (
                      myListedNftsQuery.data.map(nft => (
                        <NftTeaser
                          key={nft.nft}
                          to={localizedPath({
                            defaultLang,
                            locale,
                            path: `/nft/${nft.nft}/`,
                          })}
                          state={{ from: location.pathname }}
                          name={nft.mintData.name}
                          artist={nft.mintData?.collection?.name}
                          price={nft.price}
                          button={
                            isMe ? <UnlistModal {...{ location, nft }} /> : null
                          }
                          image={
                            <Image type="NFT" image={nft.mintData.image} />
                          }
                        />
                      ))
                    )}
                  </NftGrid>
                )}
              </Box>
            </div>
          )}
          {tab === "likes" && (
            <div>
              <Box margin={{ top: "40px" }}>
                {myLikesQuery.isLoading ? (
                  <Loader
                    messages={[
                      t("loading.yourLikes"),
                      t("loading.itTakesLongerThanExpected"),
                    ]}
                    treshold={20}
                  />
                ) : myLikesQuery.status === "error" ? (
                  <div>{t("nft.form.errorOccurred")}</div>
                ) : (
                  <NftGrid>
                    {myLikesQuery.data.length === 0 ? (
                      <>
                        {isMe ? t("user.youHaveNoLikes") : t("user.hasNoLikes")}
                      </>
                    ) : (
                      myLikesQuery.data.map(nft => (
                        <NftTeaser
                          key={nft.address}
                          to={localizedPath({
                            defaultLang,
                            locale,
                            path: `/nft/${nft.address}/`,
                          })}
                          state={{ from: location.pathname }}
                          name={nft.name}
                          price={nft.price}
                          artist={nft.collection?.name}
                          image={<Image type="NFT" image={nft.image} />}
                        />
                      ))
                    )}
                  </NftGrid>
                )}
              </Box>
            </div>
          )}
          {hasProfile &&
            data.is_artist &&
            collections.length > 0 &&
            tab === "collections" && (
              <div>
                <Box margin={{ top: "40px" }}>
                  <NftGrid>
                    {collections.map(collection => (
                      <CollectionTeaser
                        key={collection.id}
                        to={localizedPath({
                          defaultLang,
                          locale,
                          path: `/collection/${collection.symbol}/`,
                        })}
                        name={collection.name}
                        likes={collection.likes_count}
                        image={
                          <Image
                            image={collection.square_teaser_url}
                            type="NFT"
                            avatar={<Avatar image={collection.icon_url} />}
                          />
                        }
                      />
                    ))}
                  </NftGrid>
                </Box>
              </div>
            )}
        </SectionTabs>
      </Container>
    </LayoutVariant>
  )
}

export default ProfilePage

const ListModal = ({ location, nft, ownedNfts, setOwnedNfts }) => {
  const [show, setShow] = React.useState(false)
  const { t } = useTranslation()
  const [, dispatch] = useNfts()
  const wallet = useWallet()

  return (
    <>
      <Button
        onClick={() => {
          setShow(true)
        }}
        label={t("user.buttonListNFT")}
      />
      <Modal state={[show, setShow]}>
        <Grid>
          <Width>
            <NftTeaser
              state={{
                from: location.pathname,
              }}
              name={nft.mintData.name}
              artist={nft.mintData?.collection?.name}
              image={<Image type="NFT" image={nft.mintData.image} />}
            />
          </Width>
          <div style={{ maxWidth: "190px" }}>
            <Title title={t("user.buttonListNFT")} />
            <TitleNFT>{nft.mintData.name}</TitleNFT>
            <Formik
              validationSchema={yup.object().shape({
                price: yup
                  .number()
                  .required()
                  .min(0.01, t("user.list.priceIsToLow")),
              })}
              initialValues={{
                price: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                listNft(wallet, nft.mint, values.price * LAMPORT_MULTIPLIER)
                  .then(() => {
                    setShow(false)

                    // remove the nft from ownedNft
                    setOwnedNfts(
                      ownedNfts.filter(ownedNft => ownedNft.mint !== nft.mint)
                    )

                    // add nft to the listed nfts
                    dispatch({
                      type: "LIST",
                      nft: {
                        dataAccount: null,
                        nft: nft.mint,
                        price: values.price * LAMPORT_MULTIPLIER,
                        seller: wallet.publicKey.toBase58(),
                      },
                    })

                    getNftByAddress(nft.mint).catch(err => {
                      if (err.response && err.response.status === 404) {
                        addNFtInCollection(
                          process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS,
                          {
                            address: nft.mint,
                            name: nft.mintData.name,
                            symbol: nft.mintData.symbol,
                            external_json_url: nft.data.uri,
                            description: nft.mintData.description,
                            attributes: nft.mintData.attributes,
                            creators_address: nft.data.creators.map(
                              c => c.address
                            ),
                            category: nft.mintData.properties.category,
                            image_url:
                              typeof nft.mintData.image === "string"
                                ? nft.mintData.image
                                : "https://backend.nft4artists.io/storage/square-nft4artists.jpg",
                            minted_on_nft4artists: 0,
                          }
                        )
                      }
                    })
                  })
                  .catch(err => {
                    toast.error(t("unknownError"))
                  })
                  .finally(() => {
                    setSubmitting(false)
                  })
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormField
                    name="price"
                    title="SOL"
                    help={t("user.listNFT_help")}
                  >
                    <Field name="price" type="number" component={Input} />
                  </FormField>
                  <Button
                    submit
                    disabled={isSubmitting}
                    label={t("user.buttonListNFT")}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Modal>
    </>
  )
}

const UnlistModal = ({ location, nft }) => {
  const [show, setShow] = useState(false)
  const [, dispatch] = useNfts()
  const { t } = useTranslation()
  const wallet = useWallet()

  return (
    <>
      <Button
        onClick={() => {
          setShow(true)
        }}
        label={t("user.buttonUnlistNFT")}
      />
      <Modal state={[show, setShow]}>
        <Grid>
          <Width>
            <NftTeaser
              state={{ from: location.pathname }}
              name={nft.mintData.name}
              artist={nft.mintData?.collection?.name}
              price={nft.price}
              image={<Image type="NFT" image={nft.mintData.image} />}
            />
          </Width>
          <div>
            <Title title={t("user.buttonUnlistNFT")} />
            <TitleNFT>{nft.mintData.name}</TitleNFT>
            <Spacer>
              <Button
                onClick={() => {
                  unListNft(wallet, nft.nft, nft.dataAccount)
                    .then(() => {
                      setShow(false)
                      dispatch({ type: "UNLIST", address: nft.nft })
                    })
                    .catch(err => {
                      toast.error(t("unknownError"))
                    })
                }}
                label={t("confirm")}
              />
            </Spacer>
          </div>
        </Grid>
      </Modal>
    </>
  )
}

export const userProfileQuery = graphql`
  query userProfileQuery {
    allCollection(sort: { fields: statamicId, order: DESC }) {
      nodes {
        id
        name
        symbol
        square_teaser_url
        icon_url
        likes_count
        tags {
          slug
        }
        owner {
          address
        }
      }
    }
  }
`

const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #04062c;
  text-align: center;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    text-align: start;
  }
`

const SpreadGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;

  > * {
    justify-self: center;
  }

  > :last-child {
    grid-row: 1 / 2;
  }

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr auto 1fr;

    > * {
      align-self: end;
    }

    > :nth-child(2) {
      justify-self: center;
    }

    > :nth-child(3) {
      justify-self: end;
    }

    > :last-child {
      grid-row: unset;
    }
  }
`

const NftGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 25px;
`

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  transform: translateY(15px);
`

const Tab = styled.div`
  font-weight: 800;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;

  display: block;
  color: #04062c;
  letter-spacing: 0.025em;
  padding: 0 0 15px;
  border-bottom: 2px solid
    ${props => (props.$isActive ? "currentColor" : "transparent")};
  transition: color 0.4s ease-in-out;

  &:hover {
    color: #6080e9;
    opacity: 0.8;
  }
`

const SectionTabs = styled.div`
  width: 100%;
  height: auto;
`

const Following = styled.div`
  width: 100%;
  display: flex;
  gap: 45px;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
`

const ProfileName = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
`

const Address = styled.div`
  text-align: center;
  font-size: 14px;
`

const TitleNFT = styled.h4`
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
`

const Width = styled.div`
  min-width: 250px;
`
